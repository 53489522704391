import React, { Component } from "react";

// MetisMenu
import MetisMenu from "metismenujs";
import { withRouter } from "react-router-dom";
import { Link } from "react-router-dom";
import { pendingRequests } from '../../helpers/api';

//i18n
import { withTranslation } from 'react-i18next';

import { connect } from 'react-redux';

class SidebarContent extends Component {

    constructor(props) {
        super(props);
        this.state = {
            nbPendings: 0,
        };
    }

    componentDidMount() {
        this.initMenu();
        this.fetchPendingRequests();
        setInterval(
            function () {
                this.fetchPendingRequests();
            }
                .bind(this),
            10000
        );
    }

    fetchPendingRequests = () => {
        this._asyncRequest = pendingRequests().then(response => {
            if (response.status === 'SUCCESS') {
                this.setState({ nbPendings: response.pending });
            } else {
                console.log(`Fetch failed : ${response.error}`);
            }
        }).catch(error => {
            console.log(`Fetch failed : ${error}`);
        });
    }

    componentDidUpdate(prevProps) {
        if (this.props.type !== prevProps.type || this.props.location.pathname !== prevProps.location.pathname) {
            this.initMenu(true);
        } else if (this.props.user !== prevProps.user) {
            this.initMenu()
        }
    }

    initMenu(reload) {
        if (!reload) {
            new MetisMenu("#side-menu");
        }

        var matchingMenuItem = null;
        var ul = document.getElementById("side-menu");
        var items = ul.getElementsByTagName("a");

        for (var i = 0; i < items.length; ++i) {
            if ((this.props.location.pathname.indexOf(items[i].pathname) === 0 && items[i].pathname !== '/') || items[i].pathname === this.props.location.pathname) {
                matchingMenuItem = items[i];
            } else {
                this.deactivate(items[i]);
            }
        }

        if (matchingMenuItem) {
            this.activateParentDropdown(matchingMenuItem);
        }
    }

    deactivate = item => {
        item.classList.remove("active");
        return false;
    };

    activateParentDropdown = item => {
        item.classList.add("active");
        const parent = item.parentElement;

        if (parent) {
            parent.classList.add("mm-active");
            const parent2 = parent.parentElement;

            if (parent2) {
                parent2.classList.add("mm-show");

                const parent3 = parent2.parentElement;

                if (parent3) {
                    parent3.classList.add("mm-active"); // li
                    parent3.childNodes[0].classList.add("mm-active"); //a
                    const parent4 = parent3.parentElement;
                    if (parent4) {
                        parent4.classList.add("mm-active");
                    }
                }
            }
            return false;
        }
        return false;
    };

    render() {
        return (
            <React.Fragment>
                <div id="sidebar-menu">
                    <ul className="metismenu list-unstyled" id="side-menu">
                        <React.Fragment>
                            <li className="menu-title">{this.props.t('Profile')}</li>
                            <li>
                                <Link to="/company" className="waves-effect">
                                    <i className="bx bx-building"></i>
                                    <span>
                                        {this.props.t('Company')}
                                    </span>
                                </Link>
                            </li>
                            <li>
                                <Link to="/wallets" className="waves-effect">
                                    <i className="bx bx-wallet"></i>
                                    <span>
                                        {this.props.t('Wallets')}
                                    </span>
                                </Link>
                            </li>
                            <li>
                                <Link to="/idbanks" className="waves-effect">
                                    <i className="bx bx-money"></i>
                                    <span>
                                        {this.props.t('Bank accounts')}
                                    </span>
                                </Link>
                            </li>
                            <li>
                                <Link to="/accounts" className="waves-effect">
                                    <i className="bx bx-user-circle"></i>
                                    <span>
                                        {this.props.t('User accounts')}
                                    </span>
                                </Link>
                            </li>
                            <li className="menu-title">{this.props.t('Purchase / sale requests')}</li>
                            <li>
                                <Link to="/request/listing" className="waves-effect">
                                    <i className="bx bx-list-check"></i>
                                    <span>
                                        {this.state.nbPendings > 0 && <span className="badge badge-pill badge-success float-right font-weight-bold text-primary">
                                            {this.state.nbPendings}
                                        </span>}
                                        {this.props.t('Requests')}
                                    </span>
                                </Link>
                            </li>
                            <li>
                                <Link to="/request/purchase" className="waves-effect">
                                    <i className="bx bx-purchase-tag"></i>
                                    <span>{this.props.t('Purchase')} EUROP</span>
                                </Link>
                            </li>
                            <li>
                                <Link to="/request/sale" className="waves-effect">
                                    <i className="bx bx-euro"></i>
                                    <span>{this.props.t('Sell')} EUROP</span>
                                </Link>
                            </li>
                            <li className="menu-title">{this.props.t('SCHUMAN Financial info')}</li>
                            <li>
                                <Link to="/info" className="waves-effect">
                                <i className="bx bx-info-circle"></i>
                                    <span>About</span>
                                </Link>
                            </li>
                        </React.Fragment>
                    </ul>
                </div>
            </React.Fragment>
        );
    }
}

const mapStatetoProps = state => {
    const { user } = state.Login;
    const { activeToken } = state.Token;
    return { user, activeToken };
}

export default withRouter(connect(mapStatetoProps, null)(withTranslation()(SidebarContent)));
