import React from "react";
import { Row, Col } from "reactstrap";
import { PLATFORM } from "../../constants";

const Footer = () => {
  return (
    <React.Fragment>
      <footer className="footer">
        <div className="container-fluid">
          <Row>
            <Col sm={6}>{new Date().getFullYear()} © Sceme.</Col>
            <Col sm={6}>
              <div className="text-sm-right d-none d-sm-block">
                Powered by{" "}
                <a href="https://sceme.io" target="_blank" rel="noreferrer">
                  Sceme.io
                </a>{" "}
                | [{PLATFORM}]
              </div>
            </Col>
          </Row>
        </div>
      </footer>
    </React.Fragment>
  );
};

export default Footer;
