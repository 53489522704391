// @flow
import { all, fork, takeEvery } from "redux-saga/effects";

import {
	CHANGE_TOKEN
} from "./actionTypes";


/**
 * Changes the layout type
 * @param {*} param0
 */
function changeToken({ payload: token }) {
	try {
		console.log(`Switch to ${token} token`);
		localStorage.setItem('activeToken', token);
		let url = window.location.href;
		if(token === 'fa2')
			url = url.replace('erc20', token)
		else
			url = url.replace('fa2', token)
			window.location.href = url;

	} catch (error) { }
}

export function* watchToken() {
    yield takeEvery(CHANGE_TOKEN, changeToken)
}

function* TokenSaga() {
	yield all([
		fork(watchToken),
	]);
}

export default TokenSaga;