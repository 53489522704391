import React, { Component } from 'react';

import { Row, Col, CardBody } from "reactstrap";

class Welcome extends Component {

    constructor(props) {
        super(props);
        this.state = {}
    }

    render() {
        return (
            <CardBody>
                <div>
                    <h5>
                        Welcome to the SCHUMAN Financial onboarding path and thank you for your trust.
                    </h5>
                    <hr/>
                    <Row>
                    <Col className="col-xl-1 col-md-2">
                            <h1 className="m-0">
                                <i className="mdi mdi-clock-check-outline text-primary text-right"></i>
                            </h1>
                        </Col>
                        <Col className="col-10 align-self-end">
                            <p>
                                This <strong>10-minutes information gathering session </strong> is mandatory in order to start using our
                                EUROP stablecoin.
                            </p>
                        </Col>
                    </Row>
                    <p className='mt-4'>
                        Before you start, please make sure you have the following documents which should be
                        uploaded to the platform:
                    </p>
                    <p>
                        - Certificate of incorporation
                    </p>
                    <p>
                        - IDs of the Ultimate Beneficial Owners
                    </p>
                    <p>
                        - Declaration on honor from the Ultimate Beneficial Owner - (Only if there is not UBO
                        register in the country of customer incorporation)
                    </p>
                    <p>
                        - KBIS extract / of foreign country equivalent
                    </p>
                    <p>
                        - IDs of legal representative
                    </p>
                    

                    <div className="mt-3">
                        <div className="btn btn-primary btn-block waves-effect waves-light" onClick={() => this.props.handleChangeView('cgu')}>
                            Start registration
                        </div>
                    </div>
                </div>
            </CardBody>
        );
    }
}

export default Welcome;

